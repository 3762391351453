import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import Input from "../controls/input";
import InputArea from "../controls/input-area";
import CheckBox from "../controls/check-box";
import ReCAPTCHA from "react-google-recaptcha";
import Link from "../controls/link";
import Button from "../controls/button";
import Heading from "../typography/heading";

let captchaValid = false;
let dataProtection = false;

interface IRequestFormProps {
  className?: String;
  style?: any;
  defaultSubject?: string;
  onChange?: OnFormChange;
}

export type Action =
  | { type: "NAME"; value: string }
  | { type: "EMAIL"; value: string }
  | { type: "OBS"; value: string }
  | { type: "SUBJECT"; value: string | undefined }
  | { type: "MOBILE"; value: string }
  | { type: "BRAND"; value: string }
  | { type: "MODEL"; value: string }
  | { type: "YEAR"; value: string }
  | { type: "PRICE"; value: string }
  | { type: "KMS"; value: string };

type OnFormChange = (n: Action) => any;

const RequestForm: React.FC<IRequestFormProps> = ({
  className = "",
  style,
  defaultSubject = "",
  onChange
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          apiKeys {
            key
            version
            name
          }
        }
      }
    }
  `);

  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [obs, setObs] = useState("");
  let [subject, setSubject] = useState("");
  let [mobile, setMobile] = useState("");
  let [brand, setBrand] = useState("");
  let [model, setModel] = useState("");
  let [year, setYear] = useState("");
  let [kms, setKms] = useState("");
  let [price, setPrice] = useState("");

  const [valid, setValid] = useState(false);

  function captchaOnChange(value: any) {
    captchaValid = true;
    isFormValid(dataProtection, captchaValid, email, mobile);
  }

  function captchaOnError() {
    captchaValid = false;
    isFormValid(false, captchaValid, email, mobile);
  }

  function captchaOnExpired() {
    captchaValid = false;
    isFormValid(false, captchaValid, email, mobile);
  }

  function isFormValid(
    dataProt: boolean,
    captcha: boolean,
    email: string,
    mobile: string
  ) {
    setValid(captcha && dataProt && (email !== "" || mobile !== ""));
  }

  return (
    <form
      className={`${className}`}
      onSubmit={() => {
        alert(
          `Form Submited \n data: ${email} ${name} ${brand} ${model} ${year} ${obs}`
        );
      }}
    >
      <Heading className="text-tertiary-700 text-center mt-5 mb-4">
        Dados Pessoais
      </Heading>

      <div className="flex flex-col -my-2">
        <Input
          className="my-2"
          required={true}
          id="nome-input"
          value={name}
          label="Nome"
          placeholder="Nome..."
          onChange={item => {
            setName(item.target.value);
            isFormValid(dataProtection, captchaValid, email, mobile);
            if (onChange)
              onChange({
                type: "NAME",
                value: name
              });
          }}
        />
        <Input
          className="active my-2"
          disabled={true}
          required={defaultSubject === ""}
          id="email-title"
          value={defaultSubject !== "" ? defaultSubject : subject}
          label="Título"
          placeholder="Encomenda"
          onChange={item => {
            setSubject(item.target.value);
            isFormValid(dataProtection, captchaValid, email, mobile);
            if (onChange)
              onChange({
                type: "SUBJECT",
                value: subject
              });
          }}
        />
        <div className="flex flex-col lg:flex-row xl:flex-row">
          <Input
            required={true}
            value={email}
            className="flex-1 lg:pr-2 my-2"
            id="email-input"
            label="Email"
            placeholder="Email..."
            onChange={item => {
              setEmail(item.target.value);
              isFormValid(
                dataProtection,
                captchaValid,
                item.target.value,
                mobile
              );
              if (onChange)
                onChange({
                  type: "EMAIL",
                  value: email
                });
            }}
          />
          <Input
            required={true}
            value={mobile}
            className="flex-1 lg:pl-2 my-2"
            id="mobile-input"
            label="Telemóvel"
            placeholder="Telemóvel..."
            onChange={item => {
              setMobile(item.target.value);
              isFormValid(
                dataProtection,
                captchaValid,
                email,
                item.target.value
              );
              if (onChange)
                onChange({
                  type: "MOBILE",
                  value: mobile
                });
            }}
          />
        </div>

        <Heading className="text-tertiary-700 text-center mt-10 mb-4">
          Dados do Veículo
        </Heading>

        <div className="flex flex-col lg:flex-row lg:-mx-2">
          <Input
            required={true}
            value={brand}
            className="flex-grow lg:mx-2 my-2"
            id="brand-input"
            label="Marca"
            placeholder="Marca..."
            onChange={item => {
              setBrand(item.target.value);
              isFormValid(dataProtection, captchaValid, email, mobile);
              if (onChange)
                onChange({
                  type: "BRAND",
                  value: brand
                });
            }}
          />
          <Input
            required={true}
            value={model}
            className="flex-grow lg:mx-2 my-2"
            id="model-input"
            label="Modelo"
            placeholder="Modelo..."
            onChange={item => {
              setModel(item.target.value);
              isFormValid(dataProtection, captchaValid, email, mobile);
              if (onChange)
                onChange({
                  type: "MODEL",
                  value: model
                });
            }}
          />
          <Input
            required={true}
            value={year}
            className="flex-grow lg:mx-2 my-2"
            id="ano-input"
            label="Ano"
            placeholder="2010..."
            onChange={item => {
              setYear(item.target.value);
              isFormValid(dataProtection, captchaValid, email, mobile);
              if (onChange)
                onChange({
                  type: "YEAR",
                  value: year
                });
            }}
          />
        </div>
        <div className="flex flex-col lg:flex-row lg:-mx-2">
          <Input
            required={true}
            value={kms}
            className="flex-grow lg:mx-2 my-2"
            id="km-input"
            label="Kms"
            placeholder="10000..."
            onChange={item => {
              setKms(item.target.value);
              isFormValid(dataProtection, captchaValid, email, mobile);
              if (onChange)
                onChange({
                  type: "KMS",
                  value: kms
                });
            }}
          />
          <Input
            required={true}
            value={price}
            className="flex-grow lg:mx-2 my-2"
            id="price-input"
            label="Preço Máximo"
            placeholder="20000..."
            onChange={item => {
              setPrice(item.target.value);
              isFormValid(dataProtection, captchaValid, email, mobile);
              if (onChange)
                onChange({
                  type: "PRICE",
                  value: price
                });
            }}
          />
        </div>

        <InputArea
          className="my-2"
          required={true}
          id="obs"
          value={obs}
          label="Observações"
          placeholder="Observações..."
          onChange={(value: string) => {
            setObs(value);
            isFormValid(dataProtection, captchaValid, email, mobile);
            if (onChange)
              onChange({
                type: "OBS",
                value: obs
              });
          }}
        />
      </div>

      <div className="flex justify-center m-5">
        <ReCAPTCHA
          sitekey={
            (site.siteMetadata.apiKeys as any[]).find(
              x => x.version && x.version === "2"
            ).key
          }
          onChange={captchaOnChange}
          onErrored={captchaOnError}
          onExpired={captchaOnExpired}
        />
      </div>

      <div className="flex flex-col md:flex-row items-center mt-4 mb-12 -my-2 md:-my-0">
        <CheckBox
          className="flex-1 my-2 md:my-0"
          id="data-protect-check"
          state={false}
          onChange={(state: boolean) => {
            dataProtection = state;
            isFormValid(dataProtection, captchaValid, email, mobile);
          }}
        >
          <p className="overflow-visible">
            {"Aceito a partilha de informação para contacto. "}
            <Link
              className="text-blue-700 underline"
              to="https://dre.pt/pesquisa/-/search/123815982/details/maximized"
              openNewTab={true}
              internal={false}
            >
              Lei da proteção de dados
            </Link>
          </p>
        </CheckBox>

        {!valid ? (
          <Button
            disabled={true}
            className="flex-1 md:flex-initial w-full md:w-48 my-2 md:my-0 bg-highlight-600 hover:bg-highlight-500 text-black rounded focus:outline-none focus:shadow-outline"
            onClick={() => {}}
            ariaLabel="Enviar"
          >
            Enviar
          </Button>
        ) : (
          <a
            className="flex-1 md:flex-initial w-full md:w-48 my-2 md:my-0 bg-highlight-600 hover:bg-highlight-500 text-black rounded focus:outline-none focus:shadow-outline"
            href={`mailto:${site.siteMetadata.email}?subject=${subject}&body=${name} - ${mobile} %0D%0A Marca: ${brand} %0D%0A Modelo: ${model} %0D%0A Ano: ${year} Kilometragem: ${kms} Preço: ${price} %0D%0A ${obs}`}
            target="_blank"
          >
            Enviar
          </a>
        )}
      </div>
    </form>
  );
};

// tslint:disable-next-line: export-name
export default RequestForm;
