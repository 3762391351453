import React from "react";
import RequestForm from "../components/forms/request-form";
import Root from "../components/layout/root";
import { useStaticQuery, graphql } from "gatsby";
import Section from "../components/layout/section";

const Order = () => {
  const { site, allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "banner01" } }) {
        nodes {
          publicURL
        }
      }
    }
  `);

  return (
    <Root>
      <div
        className="flex flex-col justify-center h-full"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${allFile.nodes[0].publicURL}") center center / cover no-repeat`,
          visibility: "visible",
          animationName: "fadeIn",
          minHeight: "275px",
          backgroundColor: "rgba(0,0,0,0.4)"
        }}
      >
        <h1
          className="font-sans text-gray-200 font-bold text-5xl text-center"
          style={{ textShadow: "1px 1px 0 #000" }}
        >
          Formulário de Encomenda
        </h1>
      </div>
      <Section container={true} className="px-4 lg:px-32">
        <RequestForm defaultSubject="Encomenda" />
      </Section>
    </Root>
  );
};

export default Order;
